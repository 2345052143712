<template>
    <div class="w-100">
        <h1>Rekap Jawaban Siswa</h1>
        <h5>Psikotest Kecerdasan {{code}}</h5>
        <div class="p-4">

        <div class="card my-2" v-for="(question, index) in questions" :key="question.id">
            <div class="card-body">
                <h5 class="fw-bold">{{ index+1 }}. {{question.question}}</h5>
                <p class="h6 my-1" v-for="option in question.options" :key="option.id">
                    {{ option.option_text }}
                    <span v-for="answer in answers" :key="answer.id">
                        <h5 class="text-danger" v-if="answer.option_id == option.id">{{option.option_text}}</h5>
                        <!-- <h5 v-else>{{option.option_text}}</h5> -->
                    </span>
                    
                </p>
            </div>
            
        </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "RekapKecerdasanComponent",
    data(){
        return {
            datas: []
        }
    },
    props: {
        code: String,
        questions: Array,
        answers: Array
    },
    created(){
        // this.manipulateData()
        console.log(this.manipulateData);
    },
    
    computed: {
        manipulateData(){
            this.questions.forEach(question => {
                let temp = {
                    question: question.question
                }
                
                temp.option = dasdasdasd;

                question.options.forEach(option => {
                    let tmpOption = {
                        option_text: option.option_text,
                    }
                    tmpOption.isanswer
                    // $temp['option'].push(option)
                })
            });
            return this.questions;
        }
    }
}
</script>