<template>
    <TestCheckComponent/>
</template>

<script>
import TestCheckComponent from '../../components/Test/TestCheckComponent.vue'
export default {
  components: {
      TestCheckComponent
  }
};
</script>
