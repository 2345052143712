<template>
  <TestKecerdasanComponent />
  <!-- <RekapKecerdasanComponent /> -->
</template>

<script>
// @ is an alias to /src
import RekapKecerdasanComponent from '../../components/fitur/RekapKecerdasanComponent.vue';
import TestKecerdasanComponent from '../../components/Kecerdasan/TestKecerdasanComponent.vue'
import Sidebar from "@/components/Sidebar.vue";
export default {
  name: "TestKecerdasan",
  components: {
    // TestKecermatanComponent,
    // RekapKecerdasanComponent,
    TestKecerdasanComponent,
    Sidebar,
  },
  data() {
    return {
      text: "Ini adalah sebuah text",
    };
  },
};
</script>
