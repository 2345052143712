<template>
  <TestNotFoundComponent />
</template>
<script>
import TestNotFoundComponent from "../../components/Test/TestNotFoundComponent.vue";
export default {
  name: "TestNotFound",
  components: {
    TestNotFoundComponent,
  },
};
</script>
