<template>
    <section id="StartKecerdasan">
        <div id="waktuPengerjaanLandscape">
            <span style="font-size: 10px"> Waktu: </span>
            <span id="dashTimer">
                <h1>--</h1>
            </span>
            <span id="timeID" class="fw-bold mb-2">
                <p>0{{ countDown }}</p>
            </span>
        </div>
        <div class="container py-5">
            <div class="row" style="position: relative">
                <div class="col-12 col-md-8">
                    <form @submit.prevent="kecermatan">
                        <div id="kecermatan"></div>
                    </form>
                    <div class="p-3 border rounded subtitle-badge fw-bold mb-3">
                        Soal Test Kecerdasan <br />
                        <!-- Exam : {{ indexExam }} <br /> -->
                        Soal Ke :
                        {{ indexQuestion == 50 ? "Mengambil Soal" : indexQuestion + 1 }}
                    </div>
                    <div id="waktuPengerjaan" class="p-3 border mb-3 rounded text-center">
                        <div id="timePengerjaan">Waktu Pengerjaan</div>
                        <span id="timeID" class="fw-bold mb-2">
                            <h3><strong>{{hour}}</strong>:<strong>{{minute}}</strong>:<strong>{{second}}</strong></h3>
                        </span>
                    </div>
                    <div class="d-flex flex-column mb-4" style="display:none;">
                        <div v-if="statusQuestion" class="question mb-3 w-75 d-flex justify-content-center flex-column"
                            id="questionField"></div>
                        <div v-else class="loading-state">
                            <div class="loading-state-bg"></div>
                            <PulseLoader color="#8d191c" style="margin: 0 auto" />
                            <p>Mengambil Soal</p>
                        </div>
                        <div class="row">
                            <button :disabled="isDisabled" v-on:click="chooseAnswer('0')" id="btnAnswer0"
                                class="btn test-button col w-25 mx-2">
                                <span> A </span>
                            </button>
                            <br />
                            <button :disabled="isDisabled" v-on:click="chooseAnswer('1')" id="btnAnswer1"
                                class="btn test-button col w-25 mx-2">
                                <span> B </span>
                            </button>
                            <br />
                            <button :disabled="isDisabled" v-on:click="chooseAnswer('2')" id="btnAnswer2"
                                class="btn test-button col w-25 mx-2">
                                <span> C </span>
                            </button>
                            <br />
                            <button :disabled="isDisabled" v-on:click="chooseAnswer('3')" id="btnAnswer3"
                                class="btn test-button col w-25 mx-2">
                                <span> D </span>
                            </button>
                            <br />
                            <button :disabled="isDisabled" v-on:click="chooseAnswer('4')" id="btnAnswer4"
                                class="btn test-button col w-25 mx-2">
                                <span> E </span>
                            </button>
                            <br />
                        </div>
                    </div>
                    <div class="d-flex justify-content-between" style="display:none;">
                        <span class="btn btn-outline-secondary btn-lg border" v-on:click="prevQuestion()"><i
                                class="fas fa-angle-left    "></i></span>
                        <span class="btn btn-outline-secondary btn-lg border" v-on:click="nextQuestion()"><i
                                class="fas fa-angle-right    "></i></span>
                    </div>

                    <RekapKecerdasanComponent :code="test_code" :questions="questions" :answers="answer" />
                </div>
                <div class="col-12 col-md-4">
                    <div class="qind">

                        <div class="p-3 border rounded-md panel-question">
                            <h4 class="mpa-h4-headline fw-bold">Soal</h4>
                            <div id="box" class="box">
                                <div v-for="(iq, index) in totalSoal" :key="iq.id" :id="warna + index"
                                    :class="{ warna: true, border: true, }" v-on:click="moveQuestion(index)">
                                    <div :class="{
                                        qbox: true,
                                        'h-100': true,
                                        rounded: true,
                                        'text-center': true,
                                        'p-2': true,
                                        }">
                                        {{ index + 1 }}
                                    </div>
                                </div>
                            </div>
                            <span class="btn btn-outline-primary border my-2" v-on:click="flagQuestion()">Flag
                                Question</span>
                        </div>
                        <div class="my-3 modal-btn">
                            <div>
                                <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static"
                                    data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel"
                                    aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">
                                                    Yakin ingin kembali ke Dashboard?
                                                </h5>
                                                <button type="button" class="close" data-bs-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                Progress test anda akan disimpan. Anda akan tetap lanjut
                                                mengerjakan test dari test terakhir anda keluar
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                                    Close
                                                </button>
                                                <button type="button" class="btn btn-primary text-white" style="
                            background-color: #8d191c !important;
                            border: none !important;
                          " v-on:click="backToDashboard" data-bs-dismiss="modal">
                                                    Keluar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="display: block;">
                                <div style="display: flex; flex-direction: column">
                                    <button type="button" style="width: 100% !important;"
                                        class="btn btn-success fw-bold btn-lg text-white w-100 mb-2"
                                        onclick="confirm('Apakah anda yakin?')">
                                        Selesai
                                    </button>
                                    <span style="color: gray; font-size: 12px; text-align: flex-end">Jika terjadi
                                        masalah
                                        pada test, kembali ke dashboard dan
                                        masukan ulang kode</span>
                                    <button type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                                        style="width: 100% !important; flex: 1"
                                        class="btn btn-danger text-white w-100 mb-2">
                                        Kembali ke Dashboard
                                    </button>
                                </div>
                            </div>
                            <div style="display:none;">
                                <div style="display: flex; flex-direction: column">
                                    <button type="button" style="width: 100% !important;"
                                        class="btn btn-success fw-bold btn-lg text-white w-100 mb-2"
                                        onclick="confirm('Apakah anda yakin?')">
                                        Selesai
                                    </button>
                                    <span style="color: gray; font-size: 12px; text-align: flex-end">Jika terjadi
                                        masalah
                                        pada test, kembali ke dashboard dan
                                        masukan ulang kode</span>
                                    <button type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                                        style="width: 100% !important; flex: 1"
                                        class="btn btn-danger text-white w-100 mb-2">
                                        Kembali ke Dashboard
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import RekapKecerdasanComponent from "../fitur/RekapKecerdasanComponent.vue";
    import axios from "axios";
    import {
        useCookies
    } from "vue3-cookies";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import RekapKecerdasanComponent1 from "../fitur/RekapKecerdasanComponent.vue";
    export default {
        name: "TestKecerdasan",
        components: {
    PulseLoader,
    RekapKecerdasanComponent,
    RekapKecerdasanComponent1
},
        setup() {
            const {
                cookies
            } = useCookies();
            return {
                cookies,
            };
        },
        data() {
            return {
                test_code: this.cookies.get("test_code"),
                access_code: this.cookies.get("access_code"),
                warna: "warna",
                huruf: ["a", "b", "c", "d", "e"],
                kecermatans: [],
                countDown: 5,
                exam: "",
                questions: "",
                indexQuestion: -1,
                nowQuestion: "",
                nowOption: [],
                answer: [],
                fiftyAnswer: [],
                totalSoal: 0,
                statusQuestion: false,
                isDisabled: true,
                hour: "",
                minute: "",
                second: ""
            };
        },

        created() {
            this.getQuestions();
            this.countDownSendAnswer();
            this.counterDownTimer();
            // this.start();
        },
        methods: {
            start() {
                console.log(this.questions);
                if (this.answer != null) {
                    console.log(this.questions)
                    for (let idn = 0; idn < this.totalSoal; idn++) {
                        this.answer.forEach(answer => {
                            if (this.questions[idn].id == answer.question_id) {
                                document.getElementById("warna" + idn).className += " active";
                            }
                        });
                    }
                }
            },
            backToDashboard() {
                this.$router.push("/");
            },

            async getQuestions() {
                let token = this.cookies.get("X-GAN-TOKEN");
                await axios
                    .get(
                        "/banksoal/psikotest/get-psikotest-data?test_code=" +
                        this.test_code +
                        "&access_code=" +
                        this.access_code, {
                            headers: {
                                Authorization: "Bearer " + token,
                            },
                        }
                    )
                    .then((res) => {
                        // console.log(res);
                        this.exam = res.data.data.psikotest.exam[0].id;
                        let time = res.data.data.psikotest.exam[0].total_duration
                        time = time.split(":")
                        this.hour = time[0];
                        this.minute = time[1];
                        this.second = time[2];
                        // console.log(JSON.parse(res.data.data.oldAnswer))
                        if (res.data.status == 2) {
                            this.answer = JSON.parse(res.data.data.oldAnswer);
                        }
                    });

                await axios
                    .get(
                        "/banksoal/exam/" + this.exam + "?random=true", {
                            headers: {
                                Authorization: "Bearer " + token,
                            },
                        }
                    )
                    .then((res) => {
                        // console.log(res);
                        this.questions = res.data.questions;
                        this.totalSoal = this.questions.length

                    });
            },
            countDownSendAnswer() {
                let token = this.cookies.get("X-GAN-TOKEN");
                if (this.answer != null) {
                    setTimeout(() => {
                        axios.post(
                                "/banksoal/psikotest/intelligence/send-answer-intelligence", {
                                    test_code: this.cookies.get("test_code"),
                                    data: JSON.stringify(this.answer)
                                }, {
                                    headers: {
                                        Authorization: "Bearer " + token,
                                    },
                                }
                            )
                            .then((res) => {
                                console.log(res)
                            });
                        this.countDownSendAnswer()
                    }, 1000 * 60 * 10)
                }
            },

            prevQuestion() {
                if (this.indexQuestion > 0) {
                    this.moveQuestion(this.indexQuestion -= 1);
                }
            },
            nextQuestion() {

                if (this.indexQuestion < this.totalSoal - 1) {
                    this.moveQuestion(this.indexQuestion += 1);
                }
            },
            counterDownTimer() {
                if (this.countDown == 0) {
                    setTimeout(() => {

                        this.changeQuestion();
                        this.second = 59;
                        this.minute -= 1;
                        this.start();
                        this.counter();
                    }, 1000);
                }
                if (this.countDown > 0) {
                    if (this.indexExam == 11 || this.indexQuestion >= 49) {
                        return;
                    } else {
                        setTimeout(() => {
                            this.countDown -= 1;
                            this.counterDownTimer();
                        }, 1000);
                    }
                }
            },
            counter() {
                // console.log(parseInt("01"))
                if (this.second == 0) {

                    this.second = 59;

                    this.minute -= 1;
                    if (this.minute < 10) {
                        this.minute = ('000' + this.minute).slice(-2);
                    }

                    if (this.minute < 0) {
                        this.minute = 59;
                        this.hour = parseInt(this.hour)
                        this.hour -= 1;
                        if (this.hour < 10) {
                            this.hour = ('000' + this.hour).slice(-2);
                        }
                    }
                }

                if (this.second > 0) {
                    // if (parseInt(this.second) < 10) {
                    //     this.second = parseInt(this.second)
                    // }
                    setTimeout(() => {
                        this.second -= 1;
                        if (this.second < 10) {
                            this.second = ('000' + this.second).slice(-2);
                        }
                        this.counter();
                    }, 1000);

                }

            },
            moveQuestion: async function (event) {
                try {
                    $(".test-button").removeClass("active");

                    $(".warna").removeClass("currentq");
                    this.indexQuestion = event
                    this.nowQuestion = this.questions[this.indexQuestion];
                    this.nowOption = this.questions[this.indexQuestion].options;
                    if (this.answer != undefined) {
                        this.answer.forEach(elem => {
                            // console.log(elem.question_id)
                            if (elem.question_id == this.nowQuestion.id) {
                                Object.keys(this.nowOption).forEach(opt => {
                                    if (this.nowOption[opt].id == elem.option_id) {
                                        $("#btnAnswer" + opt).addClass("active")
                                        // console.log("#btnAnswer"+opt)
                                    }
                                })
                            }
                        });
                    }
                    this.showQuestion(this.indexQuestion + 1, this.nowQuestion, this.nowOption, this.huruf);
                } catch (error) {
                    // console.log(error);
                    return;
                }
            },

            flagQuestion() {
                $("#warna" + this.indexQuestion).addClass("flag")
            },


            changeQuestion() {
                try {

                    this.indexQuestion = this.indexQuestion + 1;
                    $(".test-button").removeClass("active");

                    $(".warna").removeClass("currentq");
                    this.nowQuestion = this.questions[this.indexQuestion];
                    this.nowOption = this.questions[this.indexQuestion].options;
                    if (this.answer != undefined) {
                        this.answer.forEach(elem => {
                            // console.log(elem.question_id)
                            if (elem.question_id == this.nowQuestion.id) {
                                Object.keys(this.nowOption).forEach(opt => {
                                    if (this.nowOption[opt].id == elem.option_id) {
                                        $("#btnAnswer" + opt).addClass("active")
                                        // console.log("#btnAnswer"+opt)
                                    }
                                })
                            }
                        });
                    }
                    this.showQuestion(this.indexQuestion + 1, this.nowQuestion, this.nowOption, this.huruf);
                } catch (error) {
                    // console.log(error);
                    return;
                }
            },

            questionIndicator() {
                document.getElementById("warna" + this.indexQuestion).className += " active";
            },


            showQuestion(number, question, option, hurufg) {
                try {
                    // console.log(this.globalVar)
                    $("#warna" + this.indexQuestion).addClass("currentq");
                    let qstNow = question;
                    let optNow = option;
                    let huruf = hurufg;
                    $("#dashTimer").css("display", "none");
                    this.statusQuestion = true;
                    this.isDisabled = false;

                    $("#timeID").show();
                    $("#questionField").html("");
                    $(document).ready(function () {
                        $("#questionField").append(`<div>` + number + `. ` + qstNow.question + `</div>`);

                        Object.keys(optNow).forEach(key => {

                            $("#questionField").append(`<div class="d-flex"><strong class="me-2">` +
                                huruf[key] + `.</strong><span>` + optNow[key].option_text +
                                `</span></div>`);
                        })
                    })



                    $("table").addClass("w-100");
                    $("table").css("height", "50px");
                    $("table").css("margin", "0");
                    $("td").removeClass("p-5");
                    $("td").addClass("p-1");
                } catch (error) {
                    // console.log(error);
                    return;
                }
            },

            chooseAnswer: async function (event) {
                this.questionIndicator()
                let token = this.cookies.get("X-GAN-TOKEN");
                var today = new Date();
                var date =
                    today.getFullYear() +
                    "-" +
                    (today.getMonth() + 1) +
                    "-" +
                    today.getDate();
                var time =
                    today.getHours() +
                    ":" +
                    today.getMinutes() +
                    ":" +
                    today.getSeconds();
                var dateTime = date + " " + time;

                let temp = {
                    exam_id: this.exam,
                    question_id: this.nowQuestion.id,
                    option_id: this.nowOption[event].id,
                    answerID: event,
                    time: dateTime,
                };

                let ketemu = 0;
                this.answer.forEach(answer => {
                    if (answer.question_id == this.nowQuestion.id) {
                        ketemu += 1;
                        answer.option_id = this.nowOption[event].id;
                        answer.time = dateTime
                    }
                });
                if (ketemu == 0) {
                    this.answer.push(temp)
                }
                // this.countDownSendAnswer();

                this.changeQuestion();
            }
        }
    }
</script>

<style scoped lang="scss">
    .refresh-btn {
        background-color: blue !important;
    }

    #waktuPengerjaanLandscape {
        display: none;
    }

    .flag {
        background-color: #967ed6 !important;
    }

    .loading-state {
        display: flex;
        justify-content: center;
        height: 180px;
        margin: 0 auto;
        align-items: center;
        flex-direction: column;
    }

    .loading-state-bg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: white;
        opacity: 0.5;
        z-index: 10;
    }

    .active {
        background-color: rgba($color: #8d191c, $alpha: 0.2);
        color: #8d191c;
    }

    .currentq {
        background-color: #ffc41f;
        color: black;
    }

    .test-button {
        background-color: transparent;
        border: 1px solid #bbb;
        border-radius: 3px;
        color: #8d191c;
        transition: 0.2s ease-in-out;
    }

    .test-button.active {
        transition: none;
        background-color: #8d191c;
        color: #fff;
    }

    .test-button:active {
        transition: none;
        background-color: #8d191c;
        color: #fff;
    }

    .warna:hover {
        cursor: pointer;
    }

    #box {
        // background-color: blue;
        box-sizing: border-box;
        display: inline-flex;
        flex-wrap: wrap;
    }

    #box>div {
        margin: 2px;
        width: 48px;
        height: 48px;
    }

    .custom-control-input {
        width: 20px !important;
        height: 20px !important;
        padding: 10px;
    }

    #questionField {
        width: 100% !important;
    }

    .mpa-btn-primary {
        border: 1px solid #bbb !important;
        border-radius: 3px !important;
        background-color: transparent !important;
        color: #8d191c !important;
        width: 100% !important;
    }

    .mpa-btn-primary:hover {
        background-color: #8d191c !important;
        color: #fff !important;
    }

    .mpa-btn-primary:focus {}

    #customButton {
        border: 1px solid gray;
        padding: 10px;
    }

    #timePengerjaan {
        font-size: x-large;
        font-weight: 700;
    }

    #timeID {
        font-size: x-large;
        color: #8d191c;
        font-weight: 800 !important;
    }

    #tableChoiceAnswer {
        margin-top: 40px;
    }

    #RadioA {
        width: 20px !important;
        height: 10px !important;
        border-radius: 100% !important;
        background: #5c0000 !important;
    }

    #classChoiceGan {
        margin-left: 30px;
    }

    #btnNextPrev {
        margin-top: 30px;
    }

    #buttonPrevues {
        width: 50px;
        height: 50px;
        border: 2px solid #8d191c;
        background: #8d191c;
        color: white;
        margin-right: 10px;
    }

    #buttonNext {
        width: 50px;
        height: 50px;
        border: 2px solid #8d191c;
        background: #8d191c;
        color: white;
    }

    .next {
        background-color: white;
        color: #8d191c;
        border: 2px solid grey;
        margin-left: 10px;
    }

    #illustrationStart {
        width: 255px;
    }

    .text-gan {
        color: white;
    }

    #buttonPaymentId {
        width: 410px !important;
        margin-right: 105px;
        text-decoration-line: none;
    }

    .panel-question {
        background-color: #eeeeee;
        border-radius: 8px;
    }

    .subtitle-badge {
        background-color: #fad4d4;
        color: #5c0000;
        border: 1px solid #8d191c;
    }

    @media only screen and (max-width: 600px) {
        #StartKecerdasan {
            margin-top: -70px;
        }

        #buttonPaymentId {
            width: 170px !important;
            margin-right: 65px;
            text-decoration-line: none;
            margin-top: 20px;
            margin-bottom: 100px;
        }

        #buttonPrevues {
            width: 50px;
            height: 50px;
            border: 2px solid #8d191c;
            background: #8d191c;
            color: white;
            margin-right: 90px;
        }

        #buttonNext {
            width: 50px;
            height: 50px;
            border: 2px solid #8d191c;
            background: #8d191c;
            color: white;
            margin-left: 100px;
        }

        .qind {
            display: flex;
            flex-direction: column;
        }
    }

    @media (max-width: 770px) {
        .qind {
            flex-direction: column-reverse;
        }

        .modal-btn {
            margin-top: 20px;
        }

        // #waktuPengerjaan {
        // position: absolute !important;
        // top: 0;
        // left: 0 ;
        // }
    }

    @media screen and (max-width: 767px) and (orientation: landscape) {
        #waktuPengerjaan {
            display: none;
        }

        #waktuPengerjaanLandscape {
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 10;
            border: 1px solid #bbb;
            background-color: #eeeeee;
            width: 10%;
            max-width: 10%;
            text-align: center;
            padding: 10px;
            border-bottom-right-radius: 3px;
            opacity: 0.4;
        }

        #timeId {
            height: fit-content;
        }

        #waktuPengerjaanLandscape>#timeID>p {
            font-size: 14px;
            margin: 0;
        }
    }
</style>